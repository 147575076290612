export default function (Component) {
      Component.options.frontmatter = {
  "published": "2019-11-09T00:00:00.000Z",
  "tags": [
    "diy",
    "vscode",
    "extension"
  ],
  "image": require('./screenshot-ext.png'),
  "title": "Grammarly in Code",
  "excerpt": "<p>There's no simpler writing tool than markdown. There's no elegant editor than <a href=\"https://code.visualstudio.com\" target=\"_blank\" rel=\"noopener noreferrer\">code<OutboundLink/></a>. There's no better writing environment than markdown in <span title=\"VS Code\">code</span>. It's perfect, but whenever I write, I find myself copy-pasting back and forth between Grammarly editor and VS Code editor. Grammarly makes my writing much better, but it also forces me to use their editor. Don't take me wrong I don't hate their editor, in fact, I kind of like it — clean, spacious, and responsive. But it's not sufficient for me as my articles generally have images, code snippets, figures, and sometimes markup. The lack of syntax highlight for markdown and markup is an absolute deal-breaker. If Grammarly worked in VS Code editor, wouldn't that be perfect?</p>\n"
}
    }